<!--@Time : 2021/12/14 16:51-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<!-- 当 progress = true 时，展示 overlay (遮罩层) 页面以及环形的加载动画。progress 是一个计算属性。请查看下面 progress 的注释。 -->
		<v-overlay v-if="progress">
			<v-progress-circular indeterminate size="32"></v-progress-circular>
		</v-overlay>

		<v-row>
			<v-col lg="6" md="6" cols="12">
				<v-card outlined height="340px">
					<v-img
						height="338px"
						:src="require('../../../assets/images/index_bg.jpg')"
					>
						<v-card-text class="text-right grey--text">
							<span class="text-h6 primary--text mr-1">{{
								SolarMessage["team_count"]
							}}</span
							>Teams
							<span class="text-h6 primary--text ml-2 mr-1">{{
								SolarMessage["institute_count"]
							}}</span
							>Schools
							<span class="text-h6 primary--text ml-2 mr-1">{{
								SolarMessage["country_count"]
							}}</span
							>Countries
						</v-card-text>
					</v-img>
				</v-card>
			</v-col>

			<v-col lg="3" md="3" cols="12">
				<v-card outlined height="340px">
					<v-card-title>Facts</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="6">
								<v-text-field
									:value="area_sum"
									label="Floor Area"
									suffix="m2"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="capacity_sum"
									label="PV Capacity"
									suffix="kWp"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="
										AllTeamStats['all_power_upstream']
									"
									label="Power Upstream"
									suffix="kWh"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="
										AllTeamStats['all_power_downstream']
									"
									label="Power Downstream"
									suffix="kWh"
									readonly
								></v-text-field>
							</v-col>
              <v-col cols="6">
								<v-text-field
									:value="
										AllTeamStats['all_energy_balance']
									"
									label="Energy Balance"
									suffix="kWh"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="AllTeamStats['all_carbon_emission']"
									label="Carbon Emission"
									suffix="kgCO2"
									readonly
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col lg="3" md="3" cols="12">
				<v-card outlined height="340px">
					<v-card-title>Weather</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="6">
								<v-text-field
									:value="Environment['temperature_value']"
									label="Temperature"
									suffix="°C"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['humidity_value']"
									label="Relative Humidity"
									suffix="%"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['wind_value']"
									label="Wind Velocity"
									suffix="m/s"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['direction']"
									label="Wind Direction"
									hint="N 0°, clockwise"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['pm_value']"
									label="PM2.5"
									suffix="μg/m3"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['radiation']"
									label="Solar Radiation"
									suffix="W/m2"
									readonly
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-data-table
						:headers="headers"
						:items="desserts"
						:loading="loading"
						:items-per-page="15"
					>
						<template v-slot:item.logo="{ item }">
							<v-avatar size="40">
								<img :src="item.logo" alt="..." />
							</v-avatar>
						</template>
						<template v-slot:item.actions="{ item }">
							<v-icon
								small
								class="mr-2"
								color="primary"
								@click="editItem(item)"
							>
								mdi-pencil
							</v-icon>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog v-model="dialog" max-width="600px">
			<v-card>
				<v-card-title>Edit Item</v-card-title>

				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6">
							<v-text-field
								v-model="editedItem.name_en"
								label="Team Name"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<v-text-field
								v-model="editedItem.contact"
								label="Contact"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<v-text-field
								v-model="editedItem.email"
								label="Email"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<v-text-field
								v-model="editedItem.phone_number"
								label="Telephone"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<v-text-field
								v-model="editedItem.capacity"
								label="PV Capacity"
								suffix="kW"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<v-text-field
								v-model="editedItem.floor_area"
								label="Floor Area"
								suffix="m2"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="12">
							<v-select
								v-model="editedItem.institutes"
								:items="institutes_list"
								item-text="name_en"
								item-value="id"
								label="Institutes"
								multiple
								chips
							></v-select>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" small text @click="close"
						>Cancel</v-btn
					>
					<v-btn color="primary" small text @click="save">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { adminDashboard } from "@/api/solar/team";
import { getTeam, updateTeamPatch } from "@/api/solar/team";

export default {
	name: "AdminDashboard",
	data() {
		return {
			SolarMessage: {},
			area_sum: 0,
			capacity_sum: 0,
			AllTeamStats: {},
			Environment: {},
			TeamInfo: {},
			TeamStats: {},
			headers: [
				{ text: "Team", value: "team" },
				{ text: "Logo", value: "logo" },
				{ text: "Current Score", value: "score" },
				{ text: "Current Rank", value: "rank" },
				{ text: "Floor Area (m2)", value: "floor_area" },
				{ text: "PV Capacity (kWp)", value: "capacity" },
				{ text: "Power Upstream (kWh)", value: "power_upstream" },
				{ text: "Power Downstream (kWh)", value: "power_downstream" },
				{ text: "Energy Balance (kWh)", value: "energy_balance" },
				{ text: "Carbon Emission (kgCO2)", value: "carbon_emission" },
				{ text: "Actions", value: "actions", sortable: false },
			],
			desserts: [],
			loading: false,
			dialog: false,
			editTeam: null,
			editedItem: {
				name_en: null,
				contact: null,
				email: null,
				phone_number: null,
				capacity: null,
				floor_area: null,
				institutes: null,
			},
			defaultItem: {
				name_en: null,
				contact: null,
				email: null,
				phone_number: null,
				capacity: null,
				floor_area: null,
				institutes: null,
			},
			institutes_list: JSON.parse(
				window.sessionStorage.getItem("institute_list")
			),
			progress: false,
		};
	},
	created() {
		this.progress = true;
	},

	async mounted() {
		// 管理员能看到的信息
		if (
			this.$store.getters.groups === 1 ||
			this.$store.getters.groups === 2
		) {
			this.getAdminDashboard();
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
	},

	computed: {},

	methods: {
		//初始化websocket
		// async initWebSocket(){
		//   const url = process.env.VUE_APP_WS_URL + "/ws/allsensor/";
		//   this.websock = new WebSocket(url);
		//   this.websock.onmessage = this.WebsocketOnMessage;
		//   this.websock.onopen = this.WebsocketOnOpen;
		//   this.websock.onerror = this.WebsocketOnError;
		//   this.websock.onclose = this.WebsocketClose;
		// },
		// WebsocketOnOpen(e){
		//   console.log('建立连接',e);
		//   //连接建立之后执行send方法发送数据
		//   let actions_2 = {"type_id": "last_weather"};
		//   this.WebsocketSend(JSON.stringify(actions_2));
		// },
		// WebsocketOnError(){//连接建立失败重连
		//   this.initWebSocket();
		// },
		// WebsocketOnMessage(event){ //数据接收
		//   this.Environment = JSON.parse(event.data)
		// },
		// WebsocketSend(Data){//数据发送
		//   this.websock.send(Data);
		// },
		// WebsocketClose(e){  //关闭
		//   console.log('断开连接',e);
		// },

		getAdminDashboard() {
			adminDashboard().then((res) => {
				this.SolarMessage = res["count"];
				this.area_sum = res["area"];
				this.capacity_sum = res["capacity"];
				this.AllTeamStats = res["all_carbon"];
				this.Environment = res["environment_data"];
        res['team_info_list'].forEach((item)=>{
          item.logo = process.env.VUE_APP_BACKEND_URL + item.logo
        })
				this.desserts = res["team_info_list"];
				this.loading = false;
				this.progress = false;
			});
		},

		editItem(item) {
			this.editTeam = item;
			const institutes = [];
			getTeam(item["id"]).then((res) => {
				this.editedItem.name_en = res["name_en"];
				this.editedItem.contact = res["contact"];
				this.editedItem.email = res["email"];
				this.editedItem.phone_number = res["phone_number"];
				this.editedItem.capacity = res["capacity"];
				this.editedItem.floor_area = res["floor_area"];
				res["institutes"].forEach((item) => {
					institutes.push({
						id: item["id"],
						name_en: item["name_en"],
					});
				});
				this.editedItem.institutes = institutes;
			});
			this.dialog = true;
		},

		close() {
			this.editTeam = null;
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
			});
		},

		save() {
			this.loading = true;
			updateTeamPatch(this.editTeam["id"], this.editedItem).then(() => {
				this.getAdminDashboard();
				this.close();
			});
		},
	},
};
</script>

<style scoped>
</style>