<!--@Time : 2021/11/10 17:19-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<!--仪表盘父组件-->
<template>
	<div>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<p
					class="
						text-h5
						font-weight-black
						text-uppercase
						d-flex
						align-center
					"
				>
					<v-icon large color="primary" class="mr-2"
						>mdi-view-dashboard</v-icon
					>
					Dashboard
				</p>
				<v-divider></v-divider>
			</v-col>
		</v-row>
		<v-alert
			text
			dense
			color="primary"
			icon="mdi-account-alert"
			border="left"
			class="mt-3"
		>
			Hello, <strong v-text="$store.getters.username"></strong>!
		</v-alert>
		<div>
			<component v-bind:is="currentDashBoard"></component>
		</div>
	</div>
</template>

<script>
import TeamDashboard from "./TeamDashboard";
import AdminDashboard from "./AdminDashboard";
export default {
	name: "Dashboard",
	components: { AdminDashboard, TeamDashboard },
	computed: {
		// 计算属性，根据登录用户不同显示不同界面
		condition() {
			return this.$store.getters.user_team || this.$store.getters.team;
		},
		currentDashBoard() {
			if (this.condition) {
				return "TeamDashboard";
			} else {
				return "AdminDashboard";
			}
		},
	},
	created() {},
	mounted() {},
	methods: {},
};
</script>

<style scoped>
</style>