<!--@Time : 2021/12/14 16:52-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<!-- 当 progress = true 时，展示 overlay (遮罩层) 页面以及环形的加载动画。progress 是一个计算属性。请查看下面 progress 的注释。 -->
		<v-overlay v-if="progress">
			<v-progress-circular indeterminate size="32"></v-progress-circular>
		</v-overlay>

		<v-row>
			<!-- 展示赛队 Logo 及学校信息的卡片。-->
			<v-col lg="3" md="3" cols="12">
				<v-card outlined height="340px">
					<v-img
						class="white--text align-end"
						height="182px"
						:src="project.rendering"
					>
						<v-card-title>{{ project.name }}</v-card-title>
					</v-img>
					<v-card-subtitle>{{ team_name }}</v-card-subtitle>
					<v-card-text class="text--primary">
						<div
							class="d-flex justify-space-between"
							v-for="item in institute"
							:key="item.id"
						>
							<span class="text-truncate">{{
								item.name_en
							}}</span>
							<span class="primary--text">
								{{ item.country.alpha_2 }}
							</span>
						</div>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- 展示赛队当前得分和名次的卡片。-->
			<v-col lg="3" md="3" cols="12">
				<v-card outlined height="340px" class="text-center">
					<div
						class="text-center text-h4 mt-9 primary--text"
						v-text="total"
					></div>
					<v-card-subtitle class="text-center pa-0 text-overline"
						>Current Score</v-card-subtitle
					>

					<div
						class="text-center text-h4 mt-9 primary--text"
						v-text="rank"
					></div>
					<v-card-subtitle class="text-center pa-0 text-overline"
						>Current Rank</v-card-subtitle
					>

					<v-btn
						color="primary"
						outlined
						small
						class="mt-12"
						:to="{ path: '/standing' }"
						>Standing
					</v-btn>
				</v-card>
			</v-col>

			<!-- 展示赛队建筑物信息，例如房屋面积，发电量，耗电量，碳排放量的卡片。-->
			<v-col lg="3" md="3" cols="12">
				<v-card outlined height="340px">
					<v-card-title>Facts</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="6">
								<v-text-field
									:value="floor_area"
									label="Floor Area"
									suffix="m2"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="capacity"
									label="PV Capacity"
									suffix="kWp"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="power_upstream"
									label="Power Upstream"
									suffix="kWh"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="power_downstream"
									label="Power Downstream"
									suffix="kWh"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="energy_balance"
									label="Energy Balance"
									suffix="kWh"
									readonly
								></v-text-field>
							</v-col>
              <v-col cols="6">
								<v-text-field
									:value="carbon_emission"
									label="Carbon Emission"
									suffix="kgCO2"
									readonly
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- 展示赛场环境信息的卡片。-->
			<v-col lg="3" md="3" cols="12">
				<v-card outlined height="340px">
					<v-card-title>Weather</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="6">
								<v-text-field
									:value="Environment['temperature_value']"
									label="Temperature"
									suffix="°C"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['humidity_value']"
									label="Relative Humidity"
									suffix="%"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['wind_value']"
									label="Wind Velocity"
									suffix="m/s"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['direction']"
									label="Wind Direction"
									hint="N 0°, clockwise"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['pm_value']"
									label="PM2.5"
									suffix="μg/m3"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									:value="Environment['radiation']"
									label="Solar Radiation"
									suffix="W/m2"
									readonly
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
      <!--雷达图-->
			<v-col cols="12" sm="12" lg="4">
				<v-card outlined>
					<v-card-subtitle>CONTEST SCORES</v-card-subtitle>
					<v-card-text>
						<div id="radar_container">
							<!--<Loading v-if="!radar_data.length" :loading="loading"></Loading>-->
						</div>
						<div
							v-if="radar_chart === null"
							style="height: 300px"
						></div>
					</v-card-text>
				</v-card>
			</v-col>

      <!--箱型图-->
			<v-col cols="12" sm="12" lg="8">
				<v-card outlined>
					<v-card-subtitle>BENCHMARKING</v-card-subtitle>
					<v-card-text>
						<div id="column_container">
							<!--<Loading v-if="!radar_data.length" :loading="loading"></Loading>-->
						</div>
						<div
							v-if="column_chart === null"
							style="height: 300px"
						></div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>


		<v-row>
      <!--饼图-->
			<v-col cols="12" sm="12" lg="4">
				<v-card outlined>
					<v-card-subtitle>BREAKDOWN</v-card-subtitle>
					<v-card-text>
						<div id="breakdown_container">
							<!--<Loading v-if="!breakdown_data.length" :loading="loading"></Loading>-->
						</div>
						<div
							v-if="breakdown_chart === null"
							style="height: 300px"
						></div>
					</v-card-text>
				</v-card>
			</v-col>

      <!--每日得分-->
			<v-col cols="12" sm="12" lg="8">
				<v-card outlined>
					<v-card-subtitle>DAILY SCORES</v-card-subtitle>
					<v-card-text>
						<div id="day_container">
							<!--<Loading v-if="!day_data.length" :loading="loading"></Loading>-->
						</div>
						<div
							v-if="day_chart === null"
							style="height: 300px"
						></div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { DataView, DataSet } from "@antv/data-set";
import { Chart, Util } from "@antv/g2";
import {dayScore, Median, teamDashboard} from "@/api/solar/team";
export default {
	name: "TeamDashboard",
	components: {},
	data() {
		return {
			team_name: null,

			total: 0,
      total2: 0,

			project: {
				name: null,
				rendering: "/",
			},

			institute: [],

			floor_area: 0,
			capacity: 0,

			power_upstream: 0,
			power_downstream: 0,
			carbon_emission: 0,
			energy_balance: 0,

			Environment: {},

			teamData: {},

			menu_info: {},

			rank: 0,

			radar_chart: null,
			radar_data: [],
			column_chart: null,
			column_data: [],
			breakdown_chart: null,
			breakdown_data: [],
			day_chart: null,
			day_data: [],
			standing: [],
			loading: "loading...",
			progress: false,
		};
	},
	async created() {},

	async mounted() {
		if (this.$store.getters.team) {
			this.getTeamDashboard(this.$store.getters.team.id);
		}
		this.setRadarChart();
		this.setColumnChart();
		this.setBreakdownChart();
		this.setDayChart();
	},

	// beforeDestroy() {
	//   //离开路由之前断开websocket连接
	//   if (this.websock){
	//     this.websock.close()
	//     this.standing_websock.close()
	//   }
	// },

	watch: {
		"$store.getters.team": {
			handler(newValue) {
				if (newValue) {
					this.getTeamDashboard(newValue.id);
				}
			},
		},
	},

	computed: {},

	methods: {
		getTeamDashboard(teamId) {
			this.progress = true;
			teamDashboard(teamId).then((res) => {
				this.team_name = res["team"];
				this.total = res["score"];
				this.project = {
					name: res["project"]["name"],
					rendering:
						process.env.VUE_APP_BACKEND_URL +
						res["project"]["rendering"],
				};
				this.institute = res["institute"];
				this.capacity = res["capacity"];
				this.floor_area = res["floor_area"];

				this.power_upstream = res["power_upstream"];
				this.power_downstream = res["power_downstream"];
				this.carbon_emission = res["carbon_emission"];
				this.energy_balance = res["energy_balance"];

				this.Environment = res["environment_data"];

				this.teamData = res["team_data"];

				// 设置队伍页面分数和排名
				this.radar_data = [];
				delete this.teamData["Total"];
				delete this.teamData["team"];
        delete this.teamData["Penalty"];

				Object.keys(this.teamData).forEach((key) => {
					this.radar_data.push({
						item: key,
						score: this.teamData[key],
					});
				});
				const { DataView } = DataSet;
				const dv = new DataView().source(this.radar_data);
				dv.transform({
					type: "fold",
					fields: ["score"], // 展开字段集
					key: "user", // key字段
					value: "score", // value字段
				});

        this.total2 = 0
        this.radar_data.forEach((item)=>{
          this.total2 += item['score']
        })

				if (this.radar_chart) {
					this.radar_chart.changeData(dv.rows);
				}
        if (this.breakdown_chart) {
					this.breakdown_chart.changeData(dv.rows);
				}
				this.progress = false;
			});
			Median(teamId).then((res) => {
				this.column_data = res["row"];
				this.rank = res["rank"];
				const dv = new DataView().source(this.column_data);
				dv.transform({
					type: "map",
					callback: (obj) => {
						obj.range = [
							obj.low,
							obj.q1,
							obj.median,
							obj.q3,
							obj.high,
						];
						return obj;
					},
				});
				if (this.column_chart) {
					this.column_chart.changeData(dv.rows);
				}
			});
      dayScore({team: teamId}).then( (res)=>{
        this.day_data = res
        if (this.day_chart !== null){
          this.day_chart.changeData(this.day_data)
        }
      })
		},

		setRadarChart() {
			const { DataView } = DataSet;
			const dv = new DataView().source(this.radar_data);
			dv.transform({
				type: "fold",
				fields: ["score"], // 展开字段集
				key: "user", // key字段
				value: "score", // value字段
			});

			this.radar_chart = new Chart({
				container: "radar_container",
				autoFit: true,
				height: 300,
			});
			this.radar_chart.data(dv.rows);
			this.radar_chart.scale("score", {
				min: 0,
				max: 100,
			});
			this.radar_chart.coordinate("polar", {
				radius: 0.8,
			});
			this.radar_chart.tooltip({
				shared: true,
				showCrosshairs: true,
				crosshairs: {
					line: {
						style: {
							lineDash: [4, 4],
							stroke: "#333",
						},
					},
        },
        customItems: (items) => {
          items.forEach((item)=>{
            item.value = Number(item.value).toFixed(3)
          })
          return items;
        },
			});
			this.radar_chart.axis("item", {
				line: null,
				tickLine: null,
				grid: {
					line: {
						style: {
							lineDash: null,
						},
					},
				},
			});
			this.radar_chart.axis("score", {
				line: null,
				tickLine: null,
				grid: {
					line: {
						type: "line",
						style: {
							lineDash: null,
						},
					},
				},
			});
      // this.radar_chart.label("score", (score) => {
			// 		return {
      //       content: score.toFixed(3),
			// 		};
			// 	})

			this.radar_chart
				.line()
				.position("item*score")
				.color("user")
				.size(2);
			this.radar_chart
				.point()
				.position("item*score")
				.color("user")
				.shape("circle")
				.size(4)
				.style({
					stroke: "#fff",
					lineWidth: 1,
					fillOpacity: 1,
				});
			this.radar_chart.area().position("item*score").color("user");
			this.radar_chart.render();
		},

		setColumnChart() {
			this.column_chart = new Chart({
				container: "column_container",
				autoFit: true,
				height: 300,
			});
			this.column_chart.data([]);
			this.column_chart.scale("range", {
				max: 100,
				nice: true,
			});
			this.column_chart.tooltip({
				showTitle: false,
				showMarkers: false,
        itemTpl: `<div data-index={index}>
                    {type}<br/>
                    <ul class="g2-tooltip-list">
                      <li class="g2-tooltip-list-item">
                        <div class="g2-tooltip-marker" style="background: lightgray;"></div>
                        <span class="g2-tooltip-name">Max</span>: <span class="g2-tooltip-value">{q3}</span>
                      </li>
                      <li class="g2-tooltip-list-item">
                        <div class="g2-tooltip-marker" style="background: #6395F9;"></div>
                        <span class="g2-tooltip-name">{team}</span>: <span class="g2-tooltip-value">{median}</span>
                      </li>
                      <li class="g2-tooltip-list-item">
                        <div class="g2-tooltip-marker" style="background: lightgray;"></div>
                        <span class="g2-tooltip-name">Min</span>: <span class="g2-tooltip-value">{q1}</span>
                      </li>
                    </ul>
                  </div>`,
      });

			this.column_chart.axis("type", {
				tickLine: null,
				label: {
					autoRotate: false,
					autoHide: false, // 取消自动隐藏label
					formatter(text) {
						// 字符太长添加省略号
						return text.length > 10
							? `${text.slice(0, 10)}...`
							: text;
					},
				},
			});

      this.column_chart
          .schema()
          .position("type*range")
          .shape("box")
          .tooltip(
              "type*team*low*q1*median*q3*high",
              (type, team, low, q1, median, q3, high) => {
                q1 = Number(q1).toFixed(3)
                median = Number(median).toFixed(3)
                q3 = Number(q3).toFixed(3)
                return {type, team, low, q1, median, q3, high,};
              },
          )
          .style({
            stroke: "#545454",
            fill: "#1890FF",
            fillOpacity: 0.3,
          })

			this.column_chart.interaction("active-region");

			this.column_chart.render();
		},

    setBreakdownChart() {
      const { DataView } = DataSet;
			const dv = new DataView().source(this.radar_data);
			dv.transform({
				type: "fold",
				fields: ["score"], // 展开字段集
				key: "user", // key字段
				value: "score", // value字段
			});

      this.breakdown_chart = new Chart({
        container: 'breakdown_container',
        autoFit: true,
        height: 300,
      });
      this.breakdown_chart.data(dv.rows);

      this.breakdown_chart.coordinate('theta', {
        radius: 0.75
      });
      this.breakdown_chart.tooltip({
        showMarkers: false,
        customItems: (items) => {
          items.forEach((item)=>{
            item.value = Number(item.value).toFixed(3)
          })
          return items;
        },
      });

      this.breakdown_chart
          .interval()
          .adjust('stack')
          .position('score')
          .color('item', ['#6395F9', '#61D9AB', '#657798', '#F6C022', '#7666F9', '#74CBED', '#9967BD', '#FF9D4E',  '#299999', '#FF9EC6'])
          .style({ opacity: 0.4 })
          .state({
            active: {
              style: (element) => {
                const shape = element.shape;
                return {
                  matrix: Util.zoom(shape, 1.1),
                }
              }
            }
          })
          .label("score", (score) => {
            let x = score / this.total2 * 100
            if (isNaN(x)){
              x = 0
            }
            return {
              content: (x).toFixed(3) + "%",
            };
          })

      this.breakdown_chart.interaction('element-single-selected');

      this.breakdown_chart.render();

		},

		setDayChart() {
      // this.day_chart = new Chart({
      // 	container: "day_container",
      // 	autoFit: true,
      // 	height: 300,
      // });
      // this.day_chart.data(this.day_data);
      // this.day_chart.scale({
      // 	Expected: {
      // 		min: 0,
      // 		max: 400,
      // 		sync: "value",
      // 	},
      // 	Earned: {
      // 		sync: "value",
      // 	},
      // });
      //
      // this.day_chart.axis("time", true);
      // this.day_chart.axis("Earned", false);
      // this.day_chart.axis("Expected", true);
      // this.day_chart.legend(false);
      // this.day_chart.tooltip({
      // 	shared: true,
      // 	showMarkers: false,
      // });
      // this.day_chart
      // 	.interval()
      // 	.position("time*Expected")
      // 	.color("rgba(128, 128, 128, 0.3)")
      // 	.shape("border-radius")
      // 	.tooltip("Expected");
      // this.day_chart
      // 	.interval()
      // 	.position("time*Earned")
      // 	.color("#6395F9")
      // 	.shape("border-radius")
      // 	.tooltip("Earned");
      // this.day_chart.interaction("active-region");
      // this.day_chart.render();
      this.day_chart = new Chart({
        container: 'day_container',
        autoFit: true,
        height: 300,
      });
      this.day_chart.data(this.day_data);
      this.day_chart.scale('score', {
        alias: 'score',
        nice: true,
      });
      this.day_chart.axis('time', {
        tickLine: null
      });

      this.day_chart.tooltip({
        showMarkers: false
      });
      this.day_chart.interaction('active-region');

      this.day_chart.interval().position('time*score')

      this.day_chart.render();
		},
	},
};
</script>
<style scoped>
</style>